import { Currency, ETHER, Token } from '@soumyajit4419/sdk-v2'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import EthereumLogo from '../../assets/images/btc.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

type LogoUrls = {
  [address: string]: string
}

const logoUrls: LogoUrls = {
  '0x0D8724eC276036851CA4568f13aF170c20b5ec01':
    'https://i.postimg.cc/vTHfzhMP/sray-1.png',
  '0x15D27106f063131818178a35a7C057787347755C': 'https://cryptologos.cc/logos/bitcoin-btc-logo.png',
  '0x685c2D57A747A0416A0f90C0033EeE02Ac30132F' : 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
  '0xbb0C15C29a1645858f08559Ee68726e2d169Dd66' : 'https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png',
  '0x9B86186943B6f2694a4E6024E4Fb3fE4fd0B73D5' : 'https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png'

}

const getTokenLogoURL = (address: string) => `${logoUrls[address]}`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }

      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  console.log(currency, 'sdsad')

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
