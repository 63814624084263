import React from 'react'
import PreloaderImage from '../assets/svg/loader.svg'

function Preloader() {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <img src={PreloaderImage} alt="preloader" height="200px" />
      </div>
    </>
  )
}

export default Preloader
